import React from "react";
import { Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  logo: {
    fontFamily: '"Baloo Chettan 2", cursive',
    display: 'inline-block'
  },
  logoGrayLetters:{
    color: '#c2c0c0'
  },
  logoBlackLetters:{
    color: 'black'
  }
});

export default ({size}) => {
  const classes = useStyles();
  return (
    <Typography variant={size} className={classes.logo}>
      <span className={classes.logoBlackLetters}>Fa</span>
      <span className={classes.logoGrayLetters}>E</span>
      <span className={classes.logoBlackLetters}>N</span>
      <span className={classes.logoGrayLetters}>E</span>
      <span className={classes.logoBlackLetters}>R</span>
      <span className={classes.logoGrayLetters}>O</span>
    </Typography>
  );
};
