import React from "react";
import {
  Box,
  Dialog,
  useMediaQuery,
  useTheme,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import { OpenClosePrivacyPolicy } from "../../Store/Actions/policyOpening";
import PrivacyPolicy from "../PrivacyPolicy/privacyPolicy";

const PolicyPolicyDialog = () => {
  const fullscreen = useMediaQuery(useTheme().breakpoints.down("sm"));
  const isOpen = useSelector((state) => state.policyOpening.open);
  const dispatch = useDispatch();

  const handleOpening = () => dispatch(OpenClosePrivacyPolicy());

  return (
    <Dialog
      //   className={classes.dialog}
      fullScreen={fullscreen}
      open={isOpen}
      onClose={handleOpening}
      maxWidth="lg"
    >
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <IconButton onClick={handleOpening}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Box color="black">
          <PrivacyPolicy />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PolicyPolicyDialog;
