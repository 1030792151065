import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import {
  createMuiTheme,
  responsiveFontSizes,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore } from "redux";
import { allReducer } from "./Store/Reducers";

const store = createStore(allReducer);

const theme = createMuiTheme({
  typography: {
    fontFamily: '"Baloo Chettan 2", cursive',
  },
});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <MuiThemeProvider theme={responsiveFontSizes(theme)}>
        <App />
      </MuiThemeProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
