import React, { useEffect, useState } from "react";
import {
  AppBar,
  Typography,
  Toolbar,
  Box,
  Button,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
  makeStyles,
} from "@material-ui/core";
import {
  Home,
  // LocalOffer as About,
  Call as Contact,
  // Dialpad as Calculator,
  More as Salygos,
  Menu,
} from "@material-ui/icons";
import { HashLink as Link } from "react-router-hash-link";

const useStyles = makeStyles({
  appBarTransparent: {
    backgroundColor: "inherit",
    boxShadow: "none",
    transition: "all 0.3s",
    color: "#f5f5f5",
  },
  appBarOpacity: {
    backgroundColor: "rgba(255, 255, 255,0.9)",
    boxShadow: "none",
    transition: "all 0.3s",
    color: "black",
  },
  horizontalList: {
    display: "flex",
    flexDirection: "row",
  },
  menuButton: {
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
  },
  iconColorLight: {
    color: "white",
  },
  iconColorDark: {
    color: "black",
  },
});

const selectLogo = (name) => {
  if (name === "Pradžia") return <Home />;
  // if (name === "Skaičiuoklė") return <Calculator />;
  if (name === "Kontaktai") return <Contact />;
  if (name === "Sąlygos") return <Salygos />;
};

const selectLink = (name) => {
  // if (setOpenMenu) setOpenMenu();
  if (name === "Pradžia") return `#about`;
  // if (name === "Skaičiuoklė") return `#calculator`;
  if (name === "Kontaktai") return `#contact`;
  if (name === "Sąlygos") return `salygos`;
};

const handleDrawerClick = (setOpenMenu, isDrawerOpen) => {
  if (isDrawerOpen) setOpenMenu();
};

const MenuButtons = ({
  mobile = false,
  classes,
  colorChange = false,
  setDrawer = false,
  isDrawerOpen,
}) => {
  return (
    <div>
      <List className={mobile ? null : classes.horizontalList}>
        {[
          "Pradžia",
          "Kontaktai",
          "Sąlygos",
        ].map((item, index) => {
          return (
            <Link
              key={index}
              to={`/${selectLink(item)}`}
              onClick={() => handleDrawerClick(setDrawer, isDrawerOpen)}
              className={
                colorChange ? classes.iconColorLight : classes.iconColorDark
              }
              style={{ textDecoration: "none" }}
            >
              <ListItem className={classes.menuButton} button>
                <ListItemIcon
                  className={
                    colorChange ? classes.iconColorLight : classes.iconColorDark
                  }
                >
                  {selectLogo(item)}
                </ListItemIcon>
                <ListItemText>
                  <Typography style={{ fontFamily: '"Inter", sans-serif' }}>
                    {item}
                  </Typography>
                </ListItemText>
              </ListItem>
            </Link>
          );
        })}
      </List>
    </div>
  );
};

const NavBar = () => {
  const classes = useStyles();
  const matches = useMediaQuery(useTheme().breakpoints.down("sm"));
  const [data, setData] = useState({
    appBarColor: "appBarTransparent",
    openMenu: false,
  });

  useEffect(() => {
    addListeners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addListeners = () => {
    windowScroll();
  };

  const windowScroll = () => {
    window.addEventListener("scroll", handleScrollChange);
  };

  const handleScrollChange = () => {
    if (window.scrollY > 20) {
      setData({ ...data, appBarColor: "appBarOpacity" });
    } else {
      setData({ ...data, appBarColor: "appBarTransparent" });
    }
  };

  const setOpenMenu = () => {
    setData({ ...data, openMenu: !data.openMenu });
  };

  return (
    <AppBar position="fixed" className={classes[data.appBarColor]}>
      <Box width="80%" m="auto">
        <Toolbar>
          {/* <Logo size="h3" /> */}
          <Box m="auto" display={matches ? "none" : null}>
            <MenuButtons
              classes={classes}
              colorChange={
                data.appBarColor === "appBarTransparent" ? true : false
              }
              setDrawer={setOpenMenu}
              isDrawerOpen={data.openMenu}
            />
          </Box>
          <Box ml="auto" display={matches ? null : "none"}>
            <Button onClick={setOpenMenu}>
              <Menu
                style={
                  data.appBarColor === "appBarTransparent"
                    ? { color: "white" }
                    : null
                }
                fontSize="large"
              />
            </Button>
          </Box>

          <SwipeableDrawer
            disableDiscovery={true}
            anchor="right"
            open={data.openMenu}
            onClose={setOpenMenu}
            onOpen={setOpenMenu}
          >
            <Box minWidth="300px">
              <MenuButtons
                mobile={true}
                classes={classes}
                setDrawer={setOpenMenu}
                isDrawerOpen={data.openMenu}
              />
            </Box>
          </SwipeableDrawer>
        </Toolbar>
      </Box>
    </AppBar>
  );
};

export default NavBar;
