module.exports = [
  {
    img:
      "https://www.mamadraudimas.lt/wp-content/uploads/2019/03/mama-draudimas-logo.png",
    link: "https://www.mamadraudimas.lt/"
  },
  {
    img:
      "https://www.sherlog.lt/wp-content/uploads/2019/08/logo-e1566399945892.png",
    link: "https://www.sherlog.lt/"
  }
];
