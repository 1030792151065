import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#e8dcdc",
  },
});

const Error = () => {
  const classes = useStyles();
  return (
    <Box height="100vh" className={classes.root}>
      <div style={{ height: "150px", overflow: "hidden" }}>
        <svg
          viewBox="0 0 500 150"
          preserveAspectRatio="none"
          style={{ height: "100%", width: "100%" }}
        >
          <path
            d="M-93.45,31.68 C179.68,255.69 271.49,-49.99 614.78,156.01 L500.00,0.00 L0.00,0.00 Z"
            style={{ stroke: "none", fill: "#404040" }}
          ></path>
        </svg>
      </div>
      <Box height="100%">
        <Typography align="center" variant="h1">
          404
        </Typography>
        <Typography align="center" variant="h5">
          Puslapis nerastas
        </Typography>
      </Box>
    </Box>
  );
};

export default Error;
