import React from "react";
import { Box, Divider, makeStyles, Typography } from "@material-ui/core";
import Partners from "../Partners/Partners";
import { useDispatch } from "react-redux";
import { OpenClosePrivacyPolicy } from "../../Store/Actions/policyOpening";
import PrivacyPolicyDialog from "../PrivacyPolicyDialog/PrivacyPolicyDialog";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#303030",
  },
  dividerPartners: {
    backgroundColor: "white",
    maxWidth: "300px",
    margin: "10px auto",
  },
  dividerFooter: {
    backgroundColor: "white",
  },
});

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleOpening = () => dispatch(OpenClosePrivacyPolicy());

  const PartnersSection = () => (
    <Box>
      <Typography style={{ color: "white" }} variant="h4" align="center">
        Partneriai:
      </Typography>
      <Divider className={classes.dividerPartners} />
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="center"
        alignItems="center"
      >
        <Partners />
      </Box>
    </Box>
  );

  const CopyRightSection = () => (
    <Box
      minHeight="40px"
      display="flex"
      alignItems="center"
      color="white"
      justifyContent="space-between"
    >
      <Typography
        variant="body2"
        style={{
          display: "inline-block",
          fontFamily: '"Nanum Gothic", sans-serif',
          marginRight: '30px'
        }}
      >
        <span style={{ marginRight: "10px" }}>©</span>2020{" "}
        <span style={{ fontWeight: "bold" }}>UAB FaENERO</span>. Visos teisės
        saugomos LR įstatymais.
      </Typography>
      <Typography onClick={handleOpening} style={{ cursor: "pointer" }}>
        Privatumo politika
      </Typography>
    </Box>
  );

  return (
    <Box minHeight="100px" className={classes.root}>
      <Box width="80%" m="auto">
        <PartnersSection />
        <Divider className={classes.dividerFooter} />
        <CopyRightSection />
        <PrivacyPolicyDialog />
      </Box>
    </Box>
  );
};
