import React from "react";
import { Box, Link } from "@material-ui/core";
import partnersData from "./partnersData";

export default () => {
  return partnersData.map((item, index) => (
    <Box key={index} m="10px" display="flex" alignItems="center">
      <Link href={item.link}>
        <img
          style={{ maxWidth: "100%", height: "auto" }}
          src={item.img}
          alt={item.link}
        />
      </Link>
    </Box>
  ));
};
