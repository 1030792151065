import React from "react";
import About from "./About";
// import Services from "./Services";
// import Calculator from "./Calculator";
import Contact from "./Contact";
// import {Route} from 'react-router-dom';

const HomePage = () => {
  return (
    <div>
      {/* <Route path='/about' component={About}/> */}
      <About />
      {/* <Services /> */}
      {/* <Calculator /> */}
      <Contact />
    </div>
  );
};

export default HomePage;
