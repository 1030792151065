const initialState = {
  open: false,
};

export const policyOpening = (state = initialState, action) => {
  const newLocal = "openClosePrivacyPolicy";
  switch (action.type) {
    case newLocal:
      return { ...state, open: !state.open };
    default:
      return state;
  }
};
