import React from "react";
import { Box, Typography, Button, makeStyles, styled } from "@material-ui/core";
import Image from "../../Assets/papers.jpg";
import Logo from "../../Components/Logo/Logo";
import { HashLink as Link } from "react-router-hash-link";

const useStyles = makeStyles({
    introSection: {
        color: "white",
        lineHeight: "1.6",
        letterSpacing: "1px",
    },
    bgImage: {
        background: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${Image})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
    },
});

const ContactButton = styled(Button)({
    margin: "20px 0",
    padding: "5px 30px",
    color: "black",
    borderRadius: "50px",
    boxShadow: "none",
    fontWeight: "bold",
    backgroundColor: "#d1d1d1",
    border: "solid 1px black",
    fontFamily: '"Inter", sans-serif',
    "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        color: "white",
        transition: "all 0.3s",
        padding: "5px 50px",
    },
});

const About = () => {
    const classes = useStyles();
    return (
        <Box
            id='about'
            fluid='true'
            className={classes.bgImage}
            color='text.primary'
            height='80vh'
            display='flex'
            justifyContent='center'
            alignItems='center'
            flexDirection='column'>
            <Box maxWidth='800px'>
                <Typography align='center' className={classes.introSection} variant='h3'>
                    <span>
                        <Logo size='h3' />
                    </span>
                </Typography>
                <Typography className={classes.introSection} variant='h5' align='center'>
                    Nestandartinių sprendimų partneris. Ilgalaikės transporto priemonių nuomos paslaugos.
                </Typography>
            </Box>
            <Link smooth to='#contact' style={{ textDecoration: "none", color: "inherit" }}>
                <ContactButton variant='contained'>Kontaktinė informacija</ContactButton>
            </Link>
        </Box>
    );
};

export default About;
