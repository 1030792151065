import React from "react";
import { Box, Typography, Button, makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { OpenClosePrivacyPolicy } from "../../Store/Actions/policyOpening";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#e8dcdc",
  },
  boldText: {
    fontWeight: "bold",
  },
});

const Salygos = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleOpening = () => dispatch(OpenClosePrivacyPolicy());

  return (
    <Box className={classes.root} minHeight="100vh">
      {/* <TopWave color="#404040" height="170" /> */}
      <div style={{ height: "150px", overflow: "hidden" }}>
        <svg
          viewBox="0 0 500 150"
          preserveAspectRatio="none"
          style={{ height: "100%", width: "100%" }}
        >
          <path
            d="M-93.45,31.68 C179.68,255.69 271.49,-49.99 614.78,156.01 L500.00,0.00 L0.00,0.00 Z"
            style={{ stroke: "none", fill: "#404040" }}
          ></path>
        </svg>
      </div>
      <Box height="100%">
        <Box width="80%" m="auto">
          <Typography className={classes.boldText} variant="h6">
            Nuomos sąlygos*:
          </Typography>
          <ul>
            <li>
              <Typography variant="h6">
                Avansas nuomos laikotarpiui - nuo 30 procentų.
              </Typography>
            </li>
            <li>
              <Typography variant="h6">
                Nuomos laikotarpis - iki 60 mėnesių.
              </Typography>
            </li>
            <li>
              <Typography variant="h6">
                Administravimo mokestis – nuo 750 eur.
              </Typography>
            </li>
          </ul>
          <Typography className={classes.boldText} variant="h6">
            Kitos būtinosios sąlygos**:
          </Typography>
          <ul>
            <li>
              <Typography variant="h6">
                Turtas turi būt draustas Kasko draudimu visą nuomos laikotarpį.
              </Typography>
            </li>
            <li>
              <Typography variant="h6">
                Turte turi būti sumontuota palydovinė apsaugos sistema.
              </Typography>
            </li>
            <li>
              <Typography variant="h6">
                Įmonė laikosi pinigų plovimo įstatymų, todėl
                visi mūsų klientai privalės užpildyti kliento pažinimo anketą.
              </Typography>
            </li>
          </ul>
          <Typography className={classes.boldText} variant="h6">
            *Pateiktos nuomos sąlygos nereiškia mūsų įsipareigojimo sudaryti
            nuomos sutartį.
          </Typography>
          <Typography className={classes.boldText} variant="h6">
            ** Papildomos sąlygos, papildomi mokėjimai gali būti nurodomi nuomos
            sutartyje.
          </Typography>
          <Box mt="20px">
            <Button style={{ color: "blue" }} onClick={handleOpening}>
              Privatumo politika
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Salygos;
