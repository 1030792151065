import React from "react";
import './style.css'

export default () => {
  return (
    <div>
      <meta httpEquiv="Content-Type" content="text/html;charset=utf-8" /><link href="style.css" rel="stylesheet" type="text/css" /><title>Unknown</title><div className="calibre" id="calibre_link-0">
        <p className="block_">PRIVATUMO POLITIKA</p>
        <p id="calibre_link-1" className="block_1"><b className="calibre1">UAB „Faenero“ </b>(toliau – <b className="calibre1">mes</b> arba <b className="calibre1">bendrovė</b>) į Jūsų privatumą žiūri labai rimtai ir Jūsų privatumui bei asmeninei informacijai teikia didelę reikšmę.<b className="calibre1"> </b>Todėl bendrovė sąžiningai, atsakingai ir teisėtai, laikydamasi šios privatumo politikos, ES bendrojo duomenų apsaugos reglamento (toliau – <b className="calibre1">BDAR</b>), Lietuvos Respublikos asmens teisinės apsaugos įstatymo ir kitų teisės aktų, reglamentuojančių asmens duomenų saugumą, reikalavimų tvarko Jūsų asmeninę informaciją.</p>
        <p className="block_2">Šioje privatumo politikoje (toliau – <b className="calibre1">Privatumo politika</b>) pateikiame informaciją apie tai, kokius asmens duomenis ir kokiu tikslu tvarkome, kam galime juos perduoti ir kiek laiko saugome, kokias teises Jūs, kaip duomenų subjektas, turite bei kitą svarbią informaciją, susijusią su Jūsų asmens duomenų tvarkymu.</p>
        <p className="block_2">Papildoma informacija apie tai, kaip tvarkome Jūsų asmens duomenis, gali būti pateikta sutartyse ir kituose su mūsų paslaugų teikimu susijusiuose dokumentuose.</p>
        <p className="block_">PRIVATUMO POLITIKOS TAIKYMAS</p>
        <p className="block_2">Ši Privatumo politika yra aktuali asmenims, kurie apsilanko mūsų interneto svetainėje <a href="http://www.faenero.lt" className="text_">www.faenero.lt</a> (toliau – <b className="calibre1">svetainė</b>), naudojasi ar nori pasinaudoti bendrovės paslaugomis, kreipiasi į bendrovę, pateikia bendrovei užtikrinimo priemones, atstovauja kitus asmenis tokių asmenų santykiuose su bendrove ar yra juridinių asmenų, kurie naudojasi ar ketina pasinaudoti bendrovės paslaugomis, vadovai, savininkai, dalininkai, akcininkai, naudos gavėjai, įskaitant galutinius naudos gavėjus, balsavimo teisę turintys asmenys. </p>
        <p className="block_2">Pateikdami mums asmens duomenis svetainėje, joje naršydami, pateikdami užklausą, naudodamiesi svetainėje esančia užklausos formą, Jūs patvirtinate, kad susipažinote su Privatumo politika ir su tuo, kaip mes tvarkome Jūsų asmens duomenis. Jūsų patvirtinimo, kad susipažinote su Privatumo politika mes taip pat prašome mums pateiktuose dokumentuose (pvz. paraiškose, su bendrove sudaromose sutartyse, susitarimuose, anketose).</p>
        <p className="block_2">Svetainėje gali būti nuorodos į kitų bendrovių interneto svetaines, tačiau Privatumo politika netaikoma Jums naudojantis kitų bendrovių interneto svetainėmis. Visuomet perskaitykite kitų paslaugų teikėjų privatumo pranešimus ir tiesiogiai susisiekite su jais dėl Jūsų asmens duomenų naudojimo.</p>
        <p className="block_2">Mes galime pakeisti Privatumo politiką bet kuriuo metu savo nuožiūra arba jeigu to reikalauja teisės aktai. Prieš naršydami svetainėje pasitikrinkite, ar esate susipažinę su aktualia Privatumo politikos versija.</p>
        <p className="block_3">IŠ KUR IR KOKIĄ INFORMACIJĄ APIE JUS MES GAUNAME</p>
        <p className="block_2">Jūsų asmens duomenis mes gauname <b className="calibre1">tiesiogiai iš Jūsų</b>, kuomet Jūs pateikiate mums užklausas, sudarote ar ketinate sudaryti su bendrove sutartis, užpildote paraiškas dėl bendrovės paslaugų teikimo, anketas, kitus bendrovės prašomus dokumentus, bendraujate su mumis socialiniuose tinkluose.</p>
        <p className="block_2">Jums ketinant sudaryti ar sudarant sutartis su bendrove mes tvarkome šiuos asmens duomenis:</p>
        <div className="calibre2">
          <div className="block_4"><span className="bullet_">-&nbsp;</span><span className="calibre3">su tapatybės nustatymu susijusius duomenis: vardą(-us), pavardę(-es), asmens kodą, gimimo datą, mokesčių mokėtojo kodą, lytį, pilietybę, paso duomenis, skaitmeninius tapatybės nustatymo duomenis, jei Jūsų tapatybė yra nustatoma nuotoliniu būdu, </span></div>
          <div className="block_4"><span className="bullet_">-&nbsp;</span><span className="calibre3">kontaktinius duomenis: gyvenamosios vietos adresą, adresą korespondencijai, telefono numerį, el. pašto adresą,</span></div>
          <div className="block_4"><span className="bullet_">-&nbsp;</span><span className="calibre3">su paslauga susijusius duomenis: turto, kurį ketinate išsinuomoti/ nuomojatės, identifikacinius duomenis,</span></div>
          <div className="block_4"><span className="bullet_">-&nbsp;</span><span className="calibre3">finansinę informaciją: Jūsų darbo užmokestį, kitas pajamas, pajamų šaltinį, finansinius įsipareigojimus, duomenis apie sudarytus finansavimo sandorius, turtą, įsiskolinimus, banko sąskaitos numerį, kredito istorijos duomenis, atsiskaitymo su bendrove būdą (grynaisiais pinigais ar bankiniu pavedimu), </span></div>
          <div className="block_4"><span className="bullet_">-&nbsp;</span><span className="calibre3">su Jūsų profesija susijusius duomenis: informaciją apie Jūsų darbdavį, užimamas pareigas, dalyvavimą įmonių valdyme,</span></div>
          <div className="block_4"><span className="bullet_">-&nbsp;</span><span className="calibre3">duomenis, susijusius su Jūsų šeimine padėtimi,</span></div>
          <div className="block_4"><span className="bullet_">-&nbsp;</span><span className="calibre3">kitus duomenis: atsižvelgiant į bendrovėje įgyvendinamos pinigų plovimo ir teroristų finansavimo prevencijos politikos priemones Jūsų buvimą politiškai pažeidžiamu (paveikiamu) asmeniu, Jums priskiriamą rizikos klasę, duomenis, susijusius su sankcijų Jums taikymu.</span></div>
        </div>
        <p className="block_2">Informaciją apie Jus mes galime gauti <b className="calibre1">netiesiogiai </b>iš organizacijų ir asmenų, kuriuos/ kurias atstovaujate, taip pat, Jūsų kaip potencialaus mūsų kliento prašymu, duomenis mums gali teikti tokie asmenys, kaip automobilių pardavėjai, duomenis galime gauti ir iš asmenų, ketinančių apdrausti/ apdraudusiu Jums ketinamą nuomoti/ išnuomotą turtą.</p>
        <p className="block_2">Kai į mus kreipiasi juridiniai asmenys dėl paslaugų teikimo, mes galime gauti Jūsų kaip tokio juridinio asmens atstovo, vadovo, juridinio asmens savininko, dalininko, akcininko, naudos gavėjo, asmens turinčio balsavimo teisę toliau nurodytus duomenis:</p>
        <div className="calibre2">
          <div className="block_4"><span className="bullet_">-&nbsp;</span><span className="calibre3">su tapatybės nustatymu susijusius duomenis: vardą(-us), pavardę(-es), asmens kodą, gimimo datą, lytį, pilietybę, </span></div>
          <div className="block_4"><span className="bullet_">-&nbsp;</span><span className="calibre3">kontaktinius duomenis: gyvenamosios vietos adresą, adresą korespondencijai, telefono numerį, el. pašto adresą,</span></div>
          <div className="block_4"><span className="bullet_">-&nbsp;</span><span className="calibre3">kitus duomenis: atsižvelgiant į bendrovėje įgyvendinamos pinigų plovimo ir teroristų finansavimo prevencijos politikos priemones Jūsų buvimą politiškai pažeidžiamu (paveikiamu) asmeniu, Jums priskiriamą rizikos klasę, duomenis, susijusius su sankcijų Jums taikymu, duomenis apie dalyvavimą juridiniuose asmenyse, buvimą juridinio asmens vadovu ar kitu asmeniu, kuriam priklauso lemiama balsavimo teisė.</span></div>
        </div>
        <p className="block_2">Be to, <b className="calibre1">netiesiogiai</b> iš trečiųjų asmenų mes galime gauti tokius pačius duomenis, kokius prašome pateikti iš Jūsų tiesiogiai, kaip nurodyta aukščiau.</p>
        <p className="block_2"><b className="calibre1">Netiesiogiai</b> mes taip pat galime gauti informaciją apie Jus turėdami teisėtą interesą, susijusį su rizikos mažinimu, kuomet Jūsų prašymu priimame sprendimą dėl paslaugos Jums teikimo ar teikiame Jums paslaugas, iš mums prieinamų šaltinių: kredito riziką vertinančių asmenų, registrų, įmonių, valstybinių organizacijų bei galime rinkti informaciją naudodamiesi interneto paieškos sistemomis. </p>
        <p className="block_2"><b className="calibre1">Netiesiogiai </b>iš automobilių buvimo vietos sekimo paslaugas teikiantiems asmenims, jei Jūs ar kiti asmenys, kuriems perdavėte turtą, negrąžina mums turto ar mes turime pagrįstų įtarimų, kad mūsų Jums perduotas turtas gali būti paslėptas, negrąžintas ir pan.</p>
        <p className="block_2">Mes taip pat galime <b className="calibre1">netiesiogiai</b> gauti informaciją apie Jūs iš valstybinių institucijų, kitų asmenų apie asmenims, turtui padarytą žalą, teisės aktų reikalavimų nesilaikymą, Jums naudojantis turtu, Jums išnuomotu pagal su bendrove sudarytas sutartis.</p>
        <p className="block_2">Jei Jūs mums pateikiate informaciją apie kitą asmenį, Jūs privalote tokį asmenį informuoti apie tai, kad jo asmens duomenis pateikėte mums, nurodydami, kad toks asmuo gali susipažinti su jo asmens duomenų tvarkymu šioje Privatumo politikoje ar susisiekdamas su mumis. Pateikdami mums kito asmens asmens duomenis Jūs mums patvirtinate, kad toks kitas asmuo buvo supažindintas su šia Privatumo politika, jo asmens duomenų tvarkymu ir dėl tokio tvarkymo Jūs esate teisiškai susitarę bei dėl tokio asmens duomenų tvarkymo toks asmuo sutiko.</p>
        <p className="block_2">Visus duomenis mums pateikiate laisva valia. Jei Jūs tam tikrų asmens duomenų nepateiksite, gali būti, kad tam tikrų veiksmų mes negalėsime atlikti, pavyzdžiui negalėsime Jums suteikti paslaugos, dėl kurios kreipiatės.</p>
        <p className="block_5">KOKIAS TIKSLAIS TVARKOME JŪSŲ ASMENS DUOMENIS</p>
        <p className="block_2">Paprastai mes Jūsų asmens duomenis tvarkome šiais tikslais:</p>
        <div className="calibre4">
          <div className="block_6"><span className="bullet_">-&nbsp;</span><span className="calibre3">sprendimo sudaryti paslaugų teikimo sutartį ir paslaugų teikimo sutarties sudarymo,</span></div>
          <div className="block_7"><span className="bullet_">-&nbsp;</span><span className="calibre3">Jums ketinamo perduoti/ perduoto turto draudimo,</span></div>
          <div className="block_7"><span className="bullet_">-&nbsp;</span><span className="calibre3">administruodami, atsakydami į Jūsų užklausas ar siekdami išspręsti kilusias problemas,</span></div>
          <div className="block_7"><span className="bullet_">-&nbsp;</span><span className="calibre3">siekdami apsaugoti, apginti savo teises ir teisėtus interesus, turtą,</span></div>
          <div className="block_7"><span className="bullet_">-&nbsp;</span><span className="calibre3">įgyvendindami „pažink savo klientą“ principą bendrovėje,</span></div>
          <div className="block_7"><span className="bullet_">-&nbsp;</span><span className="calibre3">vykdydami teisės aktų, įskaitant tokių kaip pinigų plovimo ir teroristų finansavimo prevencijos, reikalavimus,</span></div>
          <div className="block_7"><span className="bullet_">-&nbsp;</span><span className="calibre3">tobulindami savo paslaugas ir vystydami veiklą,</span></div>
          <div className="block_7"><span className="bullet_">-&nbsp;</span><span className="calibre3">vidaus administravimo,</span></div>
          <div className="block_7"><span className="bullet_">-&nbsp;</span><span className="calibre3">verslo perleidimo tikslais,</span></div>
          <div className="block_7"><span className="bullet_">-&nbsp;</span><span className="calibre3">buhalterinės apskaitos,</span></div>
          <div className="block_7"><span className="bullet_">-&nbsp;</span><span className="calibre3">vykdydami savo įsipareigojimus tretiesiems asmenims (tokiems, kaip Jums perduoto turto savininkams, asmenims, teikiantiems mums turto finansavimo paslaugas),</span></div>
          <div className="block_7"><span className="bullet_">-&nbsp;</span><span className="calibre3">išieškodami ir administruodami skolas, pateikdami teisinius reikalavimus, siekdami jų įvykdymo ir gynimo, patys gindamiesi nuo ieškinių ir skundų,</span></div>
          <div className="block_7"><span className="bullet_">-&nbsp;</span><span className="calibre3">komunikacijos,</span></div>
          <div className="block_7"><span className="bullet_">-&nbsp;</span><span className="calibre3">vykdydami tiesioginę rinkodarą,</span></div>
          <div className="block_8"><span className="bullet_">-&nbsp;</span><span className="calibre3">gaudami Jūsų nuomonę (grįžtamąjį ryšį) apie mus.</span></div>
        </div>
        <p className="block_2">Šiame skyriuje nurodytais tikslais Jūsų asmens duomenis mes tvarkome, kai mus tai daryti įpareigoja teisės aktai, sutartis su Jumis, mūsų teisėtas interesas, kai siekiame apsaugoti mūsų, trečiųjų asmenų ar Jūsų turtą ar interesus ar, jei esame gavę Jūsų sutikimą dėl asmens duomenų tvarkymo. </p>
        <p id="calibre_link-2" className="block_3">ASMENS Duomenų tvarkymas tiesioginės rinkodaros tikslu</p>
        <p className="block_2">Tvarkydami Jūsų asmens duomenis tiesioginės rinkodaros tikslu, Jūsų prašome pateikti sutikimą dėl tokio tvarkymo. Jei sutikimo nepateiksite – šiuo tikslu Jūsų asmens duomenų netvarkysime. Jei sutikimą pateiksite, jo atsisakyti galėsite bet kuriuo metu paspaudę atitinkamą prenumeratos atsisakymo nuorodą Jums siunčiamame pranešime arba kreipdamiesi į mus elektroniniu paštu <a href="mailto:info@faenero.lt" className="text_">info@faenero.lt</a>.</p>
        <p className="block_2"><span className="calibre5">Tiesioginės rinkodaros tikslais mes tvarkysime asmens duomenis ne ilgiau nei 3  metus nuo Jūsų sutikimo gavimo arba iki tol, kol atšauksite duotą sutikimą dėl duomenų tvarkymo šiuo tikslu, atsižvelgiant į tai, kas įvyksta anksčiau.</span></p>
        <p className="block_">ASMENS DUOMENŲ SAUGOJIMO TERMINAS</p>
        <p className="block_2">Mes užtikriname, kad Jūsų asmens duomenys būtų tvarkomi ne ilgiau, negu reikia duomenų tvarkymo tikslams pasiekti, ne ilgiau nei tai numato teisės aktai.</p>
        <p className="block_2">Įprastai asmens duomenis saugosime sutarties vykdymo metu ir 10 metų nuo tų metų, kuriais buvo įvykdyta sutartis, paskutinės kalendorinės dienos, nebent ilgesni terminai būtų būtini siekiant pateikti teisinius reikalavimus, apginti teisinius interesus, ar ilgesni saugojimo terminai būtų privalomi vykdant su dokumentų archyvavimu susijusius reikalavimus ar kitus teisės aktuose nustatytus reikalavimus.</p>
        <p className="block_2">Jei sutartis nebus sudaryta, asmens duomenis saugosime 1 metus nuo Jūsų kreipimosi dėl paslaugų teikimo gavimo dienos, nebent laikantis pinigų plovimo ir teroristų finansavimo prevencijos teisės aktų reikalavimų asmens duomenis privalėsime saugoti ilgesnį terminą ar, jei siekiant apsaugoti mūsų interesus ilgesnis asmens duomenų saugojimo terminas būtų būtinas.</p>
        <p className="block_2">Vykdydami pinigų plovimo ir teroristų finansavimo prevencijos teisės aktų reikalavimų asmens duomenis saugosime 8 metus, o kompetentingų institucijų prašymu, asmens duomenų saugojimo terminas gali būti pratęstas 2 metams.</p>
        <p className="block_2">Pasibaigus saugojimo terminui surinktus asmens duomenims arba ištrinsime, sunaikinsime arba tokie duomenys taps anoniminiais. Kai kurie kompiuterių failuose saugomi asmens duomenys tam tikrą laiką dar gali būti prieinami pasibaigus jų saugojimo terminui duomenų atsarginių kopijų sistemose, tačiau prieiga prie tokių asmens duomenų yra griežtai ribojama.</p>
        <p id="calibre_link-3" className="block_">ASMENS DUOMENŲ SAUGUMAS</p>
        <p className="block_2">Kadangi mes ypatingai vertiname Jūsų asmens duomenų saugumą mes taikome tinkamas technines ir administracines priemonės tam, kad mūsų surinkti asmens duomenys būtų apsaugomi nuo praradimo, neleistino naudojimo ir pakeitimų.</p>
        <p className="block_">KONFIDENCIALUMAS</p>
        <p className="block_2">Jūsų asmens duomenų saugumas mums ypatingai svarbus, todėl visus asmens duomenis mes tvarkome griežtai laikydamiesi konfidencialumo principo. </p>
        <p className="block_9">KAM PERDUODAME JŪSŲ ASMENS DUOMENIS</p>
        <p className="block_2">Paprastai Jūsų asmens duomenimis mes nesidaliname su kitais asmenimis. Tačiau tam, kad įvykdytume savo įsipareigojimus, mes galime perduoti asmens duomenis turto pardavėjams, iš kurių bendrovė ar bendrovės pasirinktas asmuo įsigis Jums ketinamą nuomoti/ nuomojamą turtą. </p>
        <p className="block_2">Mes taip pat galime perduoti Jūsų asmens duomenis šiems gavėjams:</p>
        <div className="calibre4">
          <div className="block_6"><span className="bullet_">-&nbsp;</span><span className="calibre3">teisines paslaugas mums teikiantiems asmenims,</span></div>
          <div className="block_7"><span className="bullet_">-&nbsp;</span><span className="calibre3">finansines paslaugas mums teikiantiems asmenims,</span></div>
          <div className="block_7"><span className="bullet_">-&nbsp;</span><span className="calibre3">Jūsų įsipareigojimų vykdymo užtikrinimo priemones pateikusiems asmenims,</span></div>
          <div className="block_7"><span className="bullet_">-&nbsp;</span><span className="calibre3">turto atpirkėjui, kuris pasibaigus nuomos paslaugoms, atpirks turtą,</span></div>
          <div className="block_7"><span className="bullet_">-&nbsp;</span><span className="calibre3">Jums išnuomoto turto savininkui, jei bendrovė nėra turto savininkas,</span></div>
          <div className="block_7"><span className="bullet_">-&nbsp;</span><span className="calibre3">buhalterinės apskaitos paslaugas teikiantiems asmenims, auditoriams, kitiems konsultantams,</span></div>
          <div className="block_7"><span className="bullet_">-&nbsp;</span><span className="calibre3">automobilių draudimo paslaugas teikiantiems asmenims,</span></div>
          <div className="block_7"><span className="bullet_">-&nbsp;</span><span className="calibre3">automobilių buvimo vietos sekimo paslaugas teikiantiems asmenims,</span></div>
          <div className="block_7"><span className="bullet_">-&nbsp;</span><span className="calibre3">pasitelktiems duomenų tvarkytojams,</span></div>
          <div className="block_7"><span className="bullet_">-&nbsp;</span><span className="calibre3">valstybės institucijoms, teisėsaugos įstaigoms bei kitiems asmenims Lietuvos Respublikos teisės aktų nustatyta tvarka,</span></div>
          <div className="block_8"><span className="bullet_">-&nbsp;</span><span className="calibre3">kitiems gavėjams, kurie nurodyti su bendrove sudarytoje sutartyje ar kituose Jums pateikiamuose dokumentuose.</span></div>
        </div>
        <p className="block_2">Gavę Jūsų sutikimą asmens duomenis galėsime teikti ir kitiems asmenims.</p>
        <p className="block_2">Perduodami Jūsų asmens duomenis kitiems asmenims mes užtikriname perduodamų asmens duomenų saugumą. </p>
        <p className="block_10">ASMENS Duomenų tvarkytojai</p>
        <p className="block_2">Kad įvykdytume Jums savo įsipareigojimus, taip pat, kad užtikrintume bendrovės veiklą mes galime pasitelkti duomenų tvarkytojus, kurie pagal mūsų nurodymus ir mūsų nustatyta apimtimi tvarkys asmens duomenis tiek, kiek tai yra būtina asmens duomenų tvarkymo tikslams pasiekti. </p>
        <p className="block_2">Pasirinkdami duomenų tvarkytojus, mes reikalaujame, kad duomenų tvarkytojai būtų įgyvendinę tinkamas organizacines ir technines saugumą užtikrinančias priemones, užtikrintų asmens duomenų saugumą ir konfidencialumą.</p>
        <p className="block_">DUOMENŲ SUBJEKTO TEISĖS</p>
        <p className="block_2">Šia Privatumo politika mes taip pat informuojame Jus, kokias teises, kaip duomenų subjektas Jūs turite.</p>
        <p className="block_2">Šia Privatumo politika yra įgyvendinama Jūsų <b className="calibre1">teisė žinoti (būti informuotam) apie savo asmens duomenų tvarkymą. </b>Šią teisę Jūs taip pat galite realizuoti kreipdamiesi į mus Privatumo politikoje nurodytu adresu, elektroniniu paštu.</p>
        <p className="block_2">Jūs turite<b className="calibre1"> teisę susipažinti su tvarkomais Jūsų asmens duomenimis ir kaip jie yra tvarkomi</b>. Šią teise Jūs galite pasinaudoti kreipdamiesi į mus Privatumo politikoje nurodytu adresu, elektroniniu paštu. Jūsų prašymu, mes suteiksime informaciją iš kur gavome Jūsų asmens duomenis, asmens duomenų saugojimo laikotarpį, kokie asmens duomenys surinkti, kokiu tikslu juos tvarkome, kam teikiame.</p>
        <p className="block_2">Paprastai tokią informaciją mes Jums teiksime nemokamai, nebent Jūsų prašymai yra akivaizdžiai nepagrįsti arba neproporcingi, visų pirma dėl jų pasikartojančio turinio. Be to, jei Jūsų prašymai yra akivaizdžiai nepagrįsti arba neproporcingi mes turime teisę atsisakyti vykdyti Jūsų prašymą.</p>
        <p className="block_2">Jei savo prašyme nenurodysite informacijos pateikimo formos, informaciją Jums pateiksime tokia pat forma, kokia gautas prašymas. </p>
        <p className="block_2">Teisė<b className="calibre1"> prašyti ištaisyti asmens duomenis bei sustabdyti asmens duomenų tvarkymo veiksmus,</b> <b className="calibre1">nustačius, kad duomenys yra neteisingi, neišsamūs ar netikslūs</b>.</p>
        <p className="block_2">Teisė <b className="calibre1">reikalauti sunaikinti asmens duomenis arba apriboti duomenų tvarkymo veiksmus, nustačius, kad asmens duomenys yra tvarkomi neteisėtai arba nesąžiningai</b>.</p>
        <p className="block_2">Teisė <b className="calibre1">nesutikti, kad būtų tvarkomi asmens duomenys, </b>išskyrus atvejus, kai šie asmens duomenys yra tvarkomi dėl mūsų ar trečiojo asmens teisėto intereso, jei Jūsų interesai nėra svarbesni.</p>
        <p className="block_2">Teisę<b className="calibre1"> atšaukti duotus sutikimus dėl duomenų tvarkymo</b> Jūs galite įgyvendinti bet kada, kreipdamiesi į mus šioje Privatumo politikoje nurodytais kontaktais, o sutikimą dėl asmens duomenų tvarkymo rinkodaros tikslu atšaukti galite ir paspaudę atitinkamą prenumeratos atsisakymo nuorodą, kuri pateikta Jums siųstame elektroniniame laiške.</p>
        <p className="block_2">Jūs galite prašyti ištrinti visus duomenis, kuriuos esame surinkę apie Jus ir tokiu būdu įgyvendinti <b className="calibre1">teisę </b><span className="text_1">„būti pamirštam“. </span>Mes turime teisę atsisakyti tenkinti Jūsų prašymą <i className="calibre6">„būti pamirštam“</i> teisės aktuose nustatytais atvejais. </p>
        <p className="block_2">Jūs turite teisę reikalauti, kad Jūsų pateikti asmens duomenys, jeigu jie tvarkomi sutikimo ar sutarties pagrindu, ir jeigu jie tvarkomi automatizuotomis priemonėmis, būtų „<b className="calibre1">perkelti“, </b>t.y. persiųsti kitam duomenų valdytojui ar perduoti Jums susistemintu, įprastai naudojamu ir kompiuterio skaitomu formatu, jeigu tai yra techniškai įmanoma. </p>
        <p className="block_2">Jūs turite teisę <b className="calibre1">nesutikti, kad Jums būtų taikomas tik automatizuotas duomenų tvarkymas, įskaitant profiliavimą.</b></p>
        <p className="block_2">Paprastai mes nepriimame automatizuotu asmens duomenų tvarkymu pagrįstų sprendimų Jūsų atžvilgiu. Jei tokius sprendimus mes vis dėlto priimtume, Jūsų prašymu, mes atliksime sprendimo peržiūrą ir visų svarbių duomenų vertinimą.</p>
        <p className="block_2"><span className="text_2">Jei mūsų vykdomo Jūsų asmens duomenų tvarkymo teisėtumas Jums kelia abejonę ar Jūs nesate patenkinti susidariusios situacijos sprendimu, Jūs turite teisę</span><span className="text_3"> dėl tokio asmens duomenų tvarkymo pateikti skundą Valstybinei duomenų apsaugos inspekcijai (</span><span className="text_2">adresu L. Sapiegos g. 17, Vilnius, el. pašto adresas </span><a href="mailto:ada@ada.lt" className="text_">ada@ada.lt</a><span className="text_2">, interneto svetainė </span><a href="https://vdai.lrv.lt/" className="text_">https://vdai.lrv.lt/</a><span className="text_4">) </span><span className="text_3">ar kompetentingam teismui. </span><span className="text_2">Mūsų veiksmus ar neveikimą Jūs turite teisę skųsti pats arba per tinkamai įgaliotą atstovą ar ne pelno įstaigą, organizaciją ar asociaciją, atitinkančią teisės aktuose nustatytus reikalavimus.</span></p>
        <p className="block_2">Jei <span id="calibre_link-4">mums pažeidus Jūsų, kaip duomenų subjekto teises, Jūs patyrėte materialinę ar nematerialinę žalą, turite teisę </span><b className="calibre1">į žalos atlyginimą</b>.</p>
        <p className="block_">NEPILNAMEČIŲ APSAUGA</p>
        <p className="block_2">Mes neteikiame paslaugų jaunesniems nei 1<span lang="en">8</span> metų asmenims. Jei mes sužinome, kad asmens duomenys buvo gauti tiesiogiai iš jaunesnių nei 18 metų asmenų, tokius asmens duomenis mes nedelsiant sunaikiname. Jei esate jaunesnis negu 18 metų, prieš pateikdami informaciją apie save, turite gauti savo tėvų arba kitų teisėtų globėjų sutikimą.</p>
        <p className="block_2">Jei Jūs esate vaiko tėvai ar teisėti globėjai ir manote, kad Jūsų vaikas pateikė mums savo asmens duomenis, nedelsiant informuokite mus apie tai, kad mes galėtume tokius duomenis nedelsiant ištrinti. </p>
        <p id="calibre_link-5" className="block_3">Socialinės žiniasklaidos priemonės</p>
        <p className="block_2">Bendrovė gali būti susikūrusi profilius socialinės žiniasklaidos priemonėse, tokiose kaip Facebook. Jei<span className="calibre5"></span>ketinate bendrauti su bendrove socialinės žiniasklaidos priemonėse, perskaitykite socialinio tinklo valdytojų privatumo pranešimus ir tiesiogiai susisiekite su jais dėl Jūsų asmens duomenų naudojimo. </p>
        <p className="block_">SLAPUKAI</p>
        <p className="block_2">Svetainėje slapukai (angl. cookies) nenaudojami. </p>
        <p className="block_">JŪSŲ KLAUSIMAI</p>
        <p className="block_2">Jei Jūs turite klausimų, susijusių su šia Privatumo politika ar kitų užklausų, susijusių su Jūsų duomenų apsauga, maloniai kviečiame kreiptis į mus šiais kontaktais:</p>
        <p className="block_11">UAB „Faenero“ </p>
        <p className="block_11">Kodas: 305268585</p>
        <p className="block_11">Registracijos adresas: Švitrigailos g. 11A, Vilnius, Lietuva</p>
        <p className="block_11">Adresas korespondencijai: Vašingtono g. 1-3, Vilnius, Lietuva</p>
        <p className="block_11">El. p.: <a href="mailto:info@faenero.lt" className="text_">info@faenero.lt</a> </p>
        <p className="block_11"><a href="http://www.faenero.lt" className="text_">www.faenero.lt</a> </p>
        <p className="block_1">Mes vertiname visus Jūsų klausimus, patarimus ir nuomonę, todėl sieksime Jums atsakyti kuo greičiau.</p>
        <p className="block_12">Dėkojame, kad skyrėte laiko susipažinimui su šia Privatumo politika.</p>
        <p className="block_1">Ši Privatumo politika paskutinį kartą atnaujinta 2020-04-27.</p>
        <p className="block_13">&nbsp;</p>
        <p className="block_2">UAB „Faenero“ </p>
        <p className="block_14">&nbsp;</p>
      </div>
    </div>
  );
};
