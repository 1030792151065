import React, { useState } from "react";
import { TextField, Box, Typography, InputAdornment, Button, CircularProgress, makeStyles, styled } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { AccountCircle, Email, PhoneAndroid, Chat, Euro, Schedule, CalendarToday, DriveEta } from "@material-ui/icons";
import EmailValidator from "email-validator";
import TopWave from "../../Assets/Wave/Top";
import axios from "axios";

const useStyles = makeStyles({
    contactField: {
        backgroundColor: "#ededed",
    },
    inputMargin: {
        margin: "10px 0",
    },
});
//i did this bc i dont know how to edit with styles this thing
const textFieldStyle = makeStyles({
    formControl: {
        "label + &": {
            marginTop: "25px",
        },
    },
});

const SubmitButton = styled(Button)({
    backgroundColor: "#501454",
    color: "white",
    fontSize: "17px",
    "&:hover": {
        backgroundColor: "#8c3691",
    },
});

const Contact = () => {
    const classes = useStyles();
    const textFieldStyled = textFieldStyle();
    const [data, setData] = useState({
        name: "",
        email: "",
        phoneNumber: "",
        content: "",
        error: [],
        loading: false,
        sendMessage: null,
        marke: "",
        model: "",
        year: "",
        price: "",
        time: "",
    });

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const validateData = (name, email, marke, model, year, price, time) => {
        let errors = [];
        if (!name) errors.push("name");
        if (!EmailValidator.validate(email)) errors.push("email");
        if (!marke) errors.push("marke");
        if (!model) errors.push("model");
        if (!year) errors.push("year");
        if (!price) errors.push("price");
        if (!time) errors.push("time");
        setData((prev) => ({ ...prev, error: errors }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (
            data.name &&
            EmailValidator.validate(data.email) &&
            data.marke &&
            data.model &&
            data.year &&
            data.price &&
            data.time
        ) {
            setData({ ...data, loading: true });
            axios({
                method: "POST",
                url: "/api.faenero/sendEmail/index.php",
                headers: { "content-type": "application/json" },
                data: {
                    name: data.name,
                    email: data.email,
                    phoneNumber: data.phoneNumber,
                    message: data.content,
                    marke: data.marke,
                    model: data.model,
                    year: data.year,
                    price: data.price,
                    time: data.time,
                },
            })
                .then((res) => {
                    let message;
                    if (res.data === "success") message = "success";
                    else message = "fail";
                    setData({
                        ...data,
                        sendMessage: message,
                        loading: false,
                        error: [],
                    });
                })
                .catch((err) =>
                    setData({
                        ...data,
                        error: [],
                        loading: false,
                        sendMessage: "fail",
                    })
                );
        } else {
            validateData(data.name, data.email, data.marke, data.model, data.year, data.price, data.time);
        }
    };

    const isMessageSent = () => {
        let text;
        let color = "success";
        if (data.sendMessage === "success") text = "Nusiųsta sėkmingai!";
        else {
            text = "Įvyko klaida, bandykite dar kartą";
            color = "error";
        }
        return (
            <Box m='20px 0'>
                <Alert fluid='true' variant='filled' severity={color}>
                    {text}
                </Alert>
            </Box>
        );
    };

    return (
        <Box id='contact'>
            {/* <TopWave color="#ededed" /> */}
            <Box display='flex' justifyContent='center' alignItems='center' height='15vh'>
                <Typography variant='h3'>Susisiekime!</Typography>
            </Box>
            <Box className={classes.contactField}>
                <TopWave color='#ffff' />
                <Box
                    // maxWidth="400px"
                    // m="auto"
                    flexWrap='wrap'
                    minHeight='500px'
                    display='flex'
                    justifyContent='space-around'
                    alignItems='center'>
                    <Box maxWidth='400px'>
                        <Typography variant='h4'>Rekvizitai:</Typography>
                        <Typography variant='subtitle1'>UAB FaENERO</Typography>
                        <Typography variant='subtitle1'>Įmonės kodas: 305268585</Typography>
                        <Typography variant='subtitle1'>PVM mokėtojo kodas:</Typography>
                        <Typography variant='subtitle1'>LT100012785113</Typography>
                        <Typography variant='subtitle1'>Vašingtono 1-3, Vilnius</Typography>
                        <Typography variant='subtitle1'>
                            El. paštas:{" "}
                            <span
                                style={{
                                    color: "blue",
                                }}>
                                info@faenero.lt
                            </span>
                        </Typography>
                        <Typography variant='subtitle1'>
                            Tel. nr.{" "}
                            <span
                                style={{
                                    color: "blue",
                                }}>
                                +37067491101
                            </span>
                        </Typography>
                    </Box>
                    <Box maxWidth='700px' m='30px'>
                        <Typography variant='caption'>* - privalomi laukai</Typography>
                        <TextField
                            className={classes.inputMargin}
                            required
                            value={data.name}
                            name='name'
                            error={data.error.includes("name") ? true : false}
                            helperText={data.error.includes("name") ? "Įveskite vardą" : null}
                            label='Vardas'
                            onChange={handleChange}
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <AccountCircle />
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <Box p='10px' border={1} borderRadius={5} borderColor='grey.500'>
                            <Typography
                                style={{
                                    color: "gray",
                                }}>
                                Įveskite automobilio duomenis žemiau esančiuose laukuose.
                            </Typography>
                            <Box display='flex' justifyContent='space-between'>
                                <TextField
                                    required
                                    value={data.marke}
                                    name='marke'
                                    error={data.error.includes("marke") ? true : false}
                                    helperText={data.error.includes("marke") ? "Įveskite gamintoją" : null}
                                    label='Gamintojas'
                                    onChange={handleChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <DriveEta />
                                            </InputAdornment>
                                        ),
                                        classes: textFieldStyled,
                                    }}
                                />
                                <TextField
                                    style={{
                                        margin: "0 10px",
                                    }}
                                    required
                                    value={data.model}
                                    name='model'
                                    error={data.error.includes("model") ? true : false}
                                    helperText={data.error.includes("model") ? "Įveskite modelį" : null}
                                    label='Modelis'
                                    onChange={handleChange}
                                    InputProps={{
                                        classes: textFieldStyled,
                                    }}
                                />
                                <TextField
                                    required
                                    value={data.year}
                                    name='year'
                                    error={data.error.includes("year") ? true : false}
                                    helperText={data.error.includes("year") ? "Įveskite pagaminimo metus" : null}
                                    label='Pagaminimo metai'
                                    onChange={handleChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <CalendarToday />
                                            </InputAdornment>
                                        ),
                                        classes: textFieldStyled,
                                    }}
                                />
                            </Box>
                            <Box mt='10px' display='flex'>
                                <TextField
                                    style={{
                                        width: "50%",
                                        marginRight: "10px",
                                    }}
                                    required
                                    value={data.price}
                                    name='price'
                                    error={data.error.includes("price") ? true : false}
                                    helperText={data.error.includes("price") ? "Įveskite kainą" : null}
                                    label='Kaina'
                                    onChange={handleChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <Euro />
                                            </InputAdornment>
                                        ),
                                        classes: textFieldStyled,
                                    }}
                                />
                                <TextField
                                    style={{
                                        width: "50%",
                                    }}
                                    required
                                    value={data.time}
                                    name='time'
                                    error={data.error.includes("time") ? true : false}
                                    helperText={data.error.includes("time") ? "Įveskite nuomos laikotarpį" : null}
                                    label='Nuomos laikotarpis'
                                    onChange={handleChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <Schedule />
                                            </InputAdornment>
                                        ),
                                        classes: textFieldStyled,
                                    }}
                                />
                            </Box>
                        </Box>

                        <TextField
                            label='El. pašto adresas'
                            required
                            value={data.email}
                            className={classes.inputMargin}
                            type='email'
                            name='email'
                            error={data.error.includes("email") ? true : false}
                            helperText={data.error.includes("email") ? "Įveskite validų el.pašto adresą" : null}
                            onChange={handleChange}
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <Email />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            label='Telefono numeris'
                            type='text'
                            className={classes.inputMargin}
                            fullWidth
                            onChange={handleChange}
                            name='phoneNumber'
                            value={data.phoneNumber}
                            autoComplete='current-password'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <PhoneAndroid />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            label='Žinutė'
                            className={classes.inputMargin}
                            fullWidth
                            value={data.content}
                            multiline
                            name='content'
                            onChange={handleChange}
                            rowsMax='4'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <Chat />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {data.sendMessage !== null ? isMessageSent() : null}
                        <SubmitButton disabled={data.loading} fullWidth size='large' onClick={handleSubmit}>
                            {data.loading ? (
                                <CircularProgress
                                    style={{
                                        color: "white",
                                    }}
                                />
                            ) : (
                                "Pateikti"
                            )}
                        </SubmitButton>
                    </Box>
                </Box>
                <TopWave color='#ededed' />
            </Box>
        </Box>
    );
};

export default Contact;
